import axiosIns from '@/libs/axios'

const getAxios = () => axiosIns.get('/calendars')
const postAxios = data => axiosIns.post('/calendars', data)
const oneAxios = id => axiosIns.get(`/calendars/${id}`)
const putAxios = (id, data) => axiosIns.put(`/calendars/${id}`, data)
const deleteAxios = id => axiosIns.delete(`/calendars/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
}
