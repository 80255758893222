import axiosIns from '@/libs/axios'

const getAxios = () => axiosIns.get('/socialStatuses')
const postAxios = data => axiosIns.post('/socialStatuses', data)
const oneAxios = id => axiosIns.get(`/socialStatuses/${id}`)
const putAxios = (id, data) => axiosIns.put(`/socialStatuses/${id}`, data)
const deleteAxios = id => axiosIns.delete(`/socialStatuses/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
}
