import axiosIns from '@/libs/axios'

const getAxios = () => axiosIns.get('/nationalities')
const postAxios = data => axiosIns.post('/nationalities', data)
const oneAxios = id => axiosIns.get(`/nationalities/${id}`)
const putAxios = (id, data) => axiosIns.put(`/nationalities/${id}`, data)
const deleteAxios = id => axiosIns.delete(`/nationalities/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
}
