import axiosAccounting from '@/libs/axios-accounting'

const getAxios = () => axiosAccounting.get('/accountTrees')
const postAxios = data => axiosAccounting.post('/accountTrees', data)
const oneAxios = id => axiosAccounting.get(`/accountTrees/${id}`)
const putAxios = (id, data) => axiosAccounting.put(`/accountTrees/${id}`, data)
const deleteAxios = id => axiosAccounting.delete(`/accountTrees/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
}
